import { Box, Typography } from "@mui/material";
import PageContainer from "../../components/common/PageContainer";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const DeleteAccount = () => (
  <PageContainer>
    <PolicyContainer>
      <Typography variant="h2" color="primary" gutterBottom>
        Бришење на кориснички профил
      </Typography>
      <Typography variant="body1" gutterBottom>
        Отворете го менито „Мој профил“ во горниот лев агол.
      </Typography>
      <Box component="img" alt="Delete step 1" src="/img/delete_account_1.jpeg" />
      <Typography variant="body1" gutterBottom>
        Изберете ја опцијата „Избриши профил“.
      </Typography>
      <Box component="img" alt="Delete step 2" src="/img/delete_account_2.jpeg" />
    </PolicyContainer>
  </PageContainer>
);

export default DeleteAccount;
