import { styled } from "styled-components/macro";
import { theme } from "./theme/theme";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import { ThemeProvider } from "styled-components/macro";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Login/Register";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { UserContext } from "./context/UserContext";
import { User } from "./api/users/types";
import Profile from "./pages/Profile";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CreatePost from "./pages/Posts/CreatePost";
import Posts from "./pages/Posts";
import SocialLoginHandler from "./pages/SocialLoginHandler";
import AboutUs from "./pages/AboutUs";
import PostDetails from "./pages/Posts/PostDetails";
import CookieConsent from "react-cookie-consent";
import Footer from "./components/Footer";
import VerifyEmailHandler from "./pages/VerifyEmailHandler";
import FriendRequests from "./pages/FriendRequests";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import CookiesPolicy from "./pages/CookiesPolicy";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EditPost from "./pages/Posts/EditPost";
import MyPosts from "./pages/Posts/MyPosts";
import { useScrollToTop } from "./hooks/utils/useScrollToTop";
import Employers from "./pages/Employers";
import Employees from "./pages/Employees";
import Blog from "./pages/Blog";
import Faq from "./pages/Faq";
import BlogArticle from "./pages/BlogArticle";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import DeleteAccount from "./pages/DeleteAccount";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  @media screen and (min-width: 768px) and (max-width: 1080px) {
    height: 100%;
    padding: 0;
    flex-direction: row;
    overflow-y: hidden;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  color: #153b4f !important;
  @media screen and (min-width: 768px) and (max-width: 1080px) {
    width: 75vw;
    margin-left: auto;
    overflow-y: auto;
  }
  @media screen and (max-width: 768px) {
    overflow-y: clip;
  }
`;

function App() {
  const [user, setUser] = useState<User | null>(null);
  ReactGA.initialize("G-K7XZXZHCZF");
  mixpanel.init("d5f3b69efe3fba9637667da3549658db", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });

  useScrollToTop();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <UserContext.Provider value={{ user, setUser }}>
          <ToastContainer />
          <MainContainer>
            <NavBar />
            <BodyContainer>
              <Routes>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="profile" element={<Profile />} />
                <Route path="posts">
                  <Route index element={<Posts />} />
                  <Route path="create" element={<CreatePost />} />
                  <Route path=":id" element={<PostDetails user={user} />} />
                  <Route path=":id/edit" element={<EditPost />} />
                  <Route path="mine" element={<MyPosts />} />
                </Route>
                <Route path="connections">
                  <Route index element={<FriendRequests />} />
                </Route>
                <Route path="oauth/:provider" element={<SocialLoginHandler />} />
                <Route path="verify-email" element={<VerifyEmailHandler />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="for-employers" element={<Employers />} />
                <Route path="for-employees" element={<Employees />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="delete-account" element={<DeleteAccount />} />
                <Route path="terms-and-conditions" element={<TermsOfService />} />
                <Route path="cookies-policy" element={<CookiesPolicy />} />
                <Route path="contact" element={<Contact />} />
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:id" element={<BlogArticle />} />
                <Route path="faq" element={<Faq />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="*" element={<h1>404</h1>} />
              </Routes>
              <Footer />
            </BodyContainer>
          </MainContainer>
        </UserContext.Provider>
      </LocalizationProvider>
      <CookieConsent
        style={{
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.secondary.dark,
          fontSize: "clamp(1.1rem, 1vw, 1.1rem)",
          display: "flex",
          alignItems: "center",
        }}
        buttonText="Се согласувам"
        buttonStyle={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          fontSize: "clamp(1.1rem, 1vw, 1.1rem)",
          borderRadius: "5px",
          padding: "10px 15px",
        }}
      >
        Оваа веб страница користи колачиња за да Ви овозможи најдобро корисничко искуство.
      </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
